
const Widgets = () => {
    return (
        <div>
            <h1>Widgets</h1>
            <p>We provide a variety of different widgets that can be implemented into your front end providing a nearly zero code for you to bootstrap.</p>

            <h3 style={{ marginBottom: '0px' }}>Item Detail</h3>
            <p style={{ marginTop: '0px' }}>Experience an instant search bar that dynamically retrieves matching ingredients and products as you type. Receive corresponding IDs for seamless integration and usage.</p>

            <h3 style={{ marginBottom: '0px' }}>Food Categories</h3>
            <p style={{ marginTop: '0px' }}>Submit your ingredients and include the relevant details of your insight request to receive valuable insights about your food.</p>

            <h3 style={{ marginBottom: '0px' }}> Nutrition Label</h3>
            <p style={{ marginTop: '0px' }}>Input a product or a list of ingredients to generate a printable Nutrition Fact Panel.</p>

        </div>
    )
}

export default Widgets