import { AppBar, Box, Button, Container, Stack, Toolbar, Typography } from "@material-ui/core";
import useWindowWidth from '../../utility/useWindowWidth';
import MobileMenu from "./MobileMenu";
import { useState } from "react";


const Navbar = ({ setActive, active, setDarkMode, darkMode }) => {
    const windowWidth = useWindowWidth();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <AppBar style={{
            position: 'fixed',
        }}>
            <Container>
                <Toolbar style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Button onClick={() => setActive("Overview")} style={{ textDecoration: 'none' }}>
                        <img src="/images/logo.svg" alt="Logo" style={{ height: 20, marginRight: 16 }} />
                    </Button>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Box direction='row'>
                            <Button href='https://b2b-demo.heali.com/' style={{ color: "#fff" }}>
                                Demo
                            </Button>
                        </Box>

                        {windowWidth <= 700 && <MobileMenu setActive={setActive} active={active} setDarkMode={setDarkMode} darkMode={darkMode} setMobileMenuOpen={setMobileMenuOpen} mobileMenuOpen={mobileMenuOpen} />}
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;
