import './App.css';
import './theme/swagger.css';
import Swagger from './swagger';
import Overview from './components/Overview';
import Authentication from './components/Authentication';
import FAQ from './components/FAQ';
import ObjectStructures from './components/ObjectStructures';
import QueryTemplates from './components/QueryTemplates';
import Navbar from './components/navigation/Navbar';
import Sidebar from './components/navigation/Sidebar';
import { useState } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { theme, darkModeTheme } from './theme';
import useWindowWidth from './utility/useWindowWidth';
import MobileMenu from './components/navigation/MobileMenu';
import GettingStarted from './components/GettingStarted';
import WidgetUsage from './components/WidgetUsage';
import Widgets from './components/Widgets';


function App() {
    const windowWidth = useWindowWidth();

    const [active, setActive] = useState('Overview')
    const [darkMode, setDarkMode] = useState(false);

    const renderComponent = () => {

        switch (active) {
            case 'Overview':
                return <Overview />;
            case 'Getting Started':
                return <GettingStarted />;
            case 'Authentication':
                return <Authentication />;
            case 'API Endpoints':
                return <Swagger />;
            case 'FAQ':
                return <FAQ />;
            case 'Object Structures':
                return <ObjectStructures />;
            case 'Query Templates':
                return <QueryTemplates />;
            case 'Widgets':
                return <Widgets />;
            case 'Widget Usage':
                return <WidgetUsage />;
            default:
                return null;
        }
    };


    console.log({ windowWidth })
    return (
        <ThemeProvider theme={(darkMode && darkModeTheme || theme)}>

            <div className="App">
                <Navbar setActive={setActive} />

                <div style={{
                    display: "flex",
                    height: "100%",
                    marginTop: '64px',
                }}>

                    {windowWidth >= 700 && <Sidebar setActive={setActive} active={active} setDarkMode={setDarkMode} darkMode={darkMode} />}


                    <div style={{
                        boxsizing: "border-box",
                        width: "100%",
                        padding: "1rem",
                        marginLeft: windowWidth >= 700 ? "218px" : "0px",
                        // top: 50
                    }}>

                        {renderComponent()}
                    </div>
                </div>

            </div>    </ThemeProvider>

    );
}

export default App;
