import { Button, Switch } from '@material-ui/core';
import menu from '../../data/menu.json';
import { useState } from 'react';

const Sidebar = ({ setActive, active, setDarkMode, darkMode }) => {
    const location = window.location.pathname;

    const handleDarkModeChange = (event) => {
        setDarkMode(event.target.checked);
        // Implement your dark mode logic here
    };

    console.log(location);
    console.log(menu);

    return (
        <div
            style={{
                position: 'fixed',
                backgroundColor: '#ececec',
                padding: '.25rem',
                width: '210px',
                // height: 'calc(100vh - 80px)',
                height: '100%',
                // top: 70,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div>

                {menu.menuItems.map((item, index) => (
                    <Button
                        key={index}
                        style={{ width: '200px', marginTop: '8px' }}
                        variant={active === item.name ? 'contained' : 'outlined'}
                        onClick={() => setActive(item.name)}
                    >
                        {item.name}
                    </Button>
                ))}
            </div>

            {/* <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <Switch
                    size='small'
                    checked={darkMode}
                    onChange={handleDarkModeChange}
                    color="primary"
                />
                <span style={{ fontSize: "12px" }}>Dark Mode</span>
            </div> */}
        </div>
    );
};

export default Sidebar;
