import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        margin: "2rem",
        overflowWrap: "break-word", // Apply word wrap to allow wrapping of long lines
        flexBasis: '600px',

    },
    title: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
}));

const CodeSnippet = ({ code }) => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <Typography variant="h6" className={classes.title}>
                {code.name}
            </Typography>
            <SyntaxHighlighter
                language={code.name}
                style={materialDark}
                showLineNumbers
            >
                {code.snippet}
            </SyntaxHighlighter>
        </div>
    );
};

export default CodeSnippet;
