import ObjectStructuresData from '../data/objectStructurers.json';
import DisplayJSON from '../elements/DisplayJson';
import ObjectCard from '../elements/ObjectStructureCard';

const ObjectStructures = () => {

    const jsonToHTML = (json) => {
        if (typeof json !== "object" || json === null) {
            return json;
        }

        let html = "";
        if (Array.isArray(json)) {
            html += "<ul>";
            json.forEach((item) => {
                html += "<li>" + jsonToHTML(item) + "</li>";
            });
            html += "</ul>";
        } else {
            html += "<ul>";
            Object.keys(json).forEach((key) => {
                html += "<li><strong>" + key + ":</strong> " + jsonToHTML(json[key]) + "</li>";
            });
            html += "</ul>";
        }

        return html;
    }


    return (
        <div>
            <h1>Object Structures</h1>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                {ObjectStructuresData.map((objectStructure, index) => (
                    <ObjectCard key={index} objectStructure={objectStructure} />
                ))}
            </div>

        </div>
    );
}

export default ObjectStructures;
