import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light', // Default mode is light
        primary: {
            main: '#48817A',
            light: '#CCEAE7',
            dark: '#48817A'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#fff', // Set the default text color to white
                },
                containedPrimary: {
                    backgroundColor: '#48817A',
                    '&:hover': {
                        backgroundColor: '#CCEAE7',
                    },
                },
            },
        },
    },
});

// Dark mode configuration
const darkModeTheme = createTheme({
    palette: {
        mode: 'dark', // Set mode to dark
        primary: {
            main: '#48817A',
            light: '#CCEAE7',
            dark: '#48817A'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: '#48817A',
                    '&:hover': {
                        backgroundColor: '#CCEAE7',
                    },
                },
            },
        },
    },
});

export { theme, darkModeTheme };
