const data = [
    {
        "name": "Curl",
        "snippet": `curl -X GET https://www.heali.ai/api/v1/<your_endpoint>? \
<your_query_params> -H 'x-api-key: <your_api_key>'`,
    },
    {
        "name": "Ruby",
        "snippet": `require 'net/http'
require 'json'

uri = URI('https://www.heali.ai/api/v1/<your_endpoint>')
params = { "<your_param_name>" => "<your_param_value>" }
uri.query = URI.encode_www_form(params)
req = Net::HTTP::Get.new(uri)
req['x-api-key'] = "<your_api_key>"
response = Net::HTTP.start(uri.hostname, uri.port,
            :use_ssl => uri.scheme == 'https'){|http| http.request(req)}
example_response = JSON.parse(response.body)
`,
    },
    {
        "name": "Python",
        "snippet": `import requests
import json

u = 'https://www.heali.ai/api/v1/<your_end_point>'
p = {'<your_param_name>': '<your_param_value>'}
h = {'x-api-key': '<your_api_key>'}
r = requests.get(u, params=p, headers=h)
example_response = r.json()
`,
    },
    {
        "name": "Java",
        "snippet": `package com.mkyong;

import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.net.HttpURLConnection;
import java.net.URL;

public class HealiAPI {
  public static void main(String[] args) throws Exception {
    HealiAPI http = new HealiAPI();
    http.sendGet();
  }

  private void sendGet() throws Exception {
    String url = "https://www.heali.ai/api/v1/<your_endpoint>?<your_query_params>";
    URL obj = new URL(url);
    HttpURLConnection con = (HttpURLConnection) obj.openConnection();
    con.setRequestProperty("x-user-api", "<your_api_key>");
    BufferedReader in = new BufferedReader(
      new InputStreamReader(con.getInputStream()));
    String inputLine;
    StringBuffer example_response = new StringBuffer();
    while ((inputLine = in.readLine()) != null) {
      example_response.append(inputLine);
    }
    in.close();
  }
}
`,
    },
    {
        "name": "Node.js",
        "snippet": `const request = require('request');

const options = {
  url: 'https://www.heali.ai/api/v1/<your_endpoint>',
  headers: { x-api-key: '<your_api_key>' },
  qs: { <your_param_name>: '<your_param_value>' }
};

function callback(error, response, body) {
  const example_response = JSON.parse(body);
}

request(options, callback);
`,
    },
    {
        "name": "Go",
        "snippet": `package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {
  req, err := http.NewRequest("GET", "https://www.heali.ai/api/v1/<your_endpoint>", nil)
  req.Header.Add("x-api-key","<your_api_key>")
  if err != nil {
    fmt.Println(err)
  }
  q := req.URL.Query()
  q.Add("<your_param_name>", "<your_param_value>")
  req.URL.RawQuery = q.Encode()
  client := &http.Client{}
  resp, err := client.Do(req)
  example_response, _ := ioutil.ReadAll(resp.Body)
}
`,
    }

]
export default data