import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqData from '../data/faqData.json';

const useStyles = makeStyles((theme) => ({
    section: {
        // padding: '2rem'
    },
    accordion: {
        '& .MuiAccordion-root': {
            marginBottom: theme.spacing(2),
            backgroundColor: '#fff',
            borderRadius: theme.spacing(1),
            boxShadow: theme.shadows[1],
        },
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#f5f5f5',
            borderBottom: '1px solid #ddd',
        },
        '& .MuiAccordionSummary-content': {
            margin: '12px 0',
        },
        '& .MuiAccordionSummary-expandIcon': {
            color: '#555',
        },
        '& .MuiAccordionDetails-root': {
            padding: theme.spacing(2),
        },
    },
}));

const FAQ = () => {
    const classes = useStyles();

    return (
        <div className={classes.section}>
            <h1>Frequently Asked Questions</h1>
            <div className={classes.accordion}>
                {faqData.map((item, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
