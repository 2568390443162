import CodeSnippet from "../elements/CodeSnippet"

const GettingStarted = () => {
    return (
        <div>
            <h1>Getting Started: A quick start guide</h1>

            <h3>Base URL</h3>
            <h5>https://healiapp.com</h5>
            <p>To use the base URL in your API call, concatenate it with the endpoint you want to access. For example, if you want to access the endpoint /v2/ingredient-declaration, you would make the API call to https://healiapp.com/v2/ingredient-declaration. Ensure that the base URL is properly configured and includes the necessary protocol (e.g.: https://).</p>

            <h3>Header Format</h3>
            <p>Make sure you have already received your API keys. You will need to include these with every call. Enter them into the header like the example below.</p>
            <CodeSnippet code={{
                snippet: `
{
    "authorization": "Bearer <token>",
    "x-api-key": "<api-key>"
    "x-client-id": "<client-id>"
}
                `}} />

            <h3>Request Example: </h3>
            <p>Send a POST request to /v2/ingredient-declaration</p>

            <h5>Payload:</h5>
            <CodeSnippet code={{
                snippet: `
{
    "formulary": [
        {
            "id": 199,
            "name": "Broccoli",
            "through": {
            "quantity": 100
            },
            "unit": {
                "_object": "Unit",
                "id": 44,
                "name": "Gram"
            },
            "type": "ingredient"
        },
        {
            "id": 282,
            "name": "Cheese Culture",
            "through": {
                "quantity": 100
            },
            "unit": {
                "id": 6,
                "name": "Milligram"
        
            "type": "ingredient"
        }
    ]
}
                `}} />

            <h3>Response Example</h3>
            <CodeSnippet code={{
                snippet: `
{
    "success": true,
    "ingredientDeclation": "Broccoli, Cheese Culture"
}
                `}} />

        </div>
    )
}

export default GettingStarted