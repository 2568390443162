import CodeSnippet from "../elements/CodeSnippet";
import queryTemplateData from "../data/queryTemplateData.js";

const QueryTemplates = () => {
    return (
        <div style={{
            maxWidth: '100%',
        }}>
            <h1>Query Templates</h1>
            <h3>Based on Programming Language</h3>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                {queryTemplateData.map((code, index) => (
                    <CodeSnippet code={code} />
                ))}
            </div>

        </div>
    )
}
export default QueryTemplates;