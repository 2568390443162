import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import DisplayJSON from "./DisplayJson";

const useStyles = makeStyles((theme) => ({
    section: {
        backgroundColor: "#f9f9f9",
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(4),
        flexGrow: 1,
        margin: ".5rem",
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    notes: {
        marginTop: theme.spacing(2),
        fontWeight: "bold",
    },
    noteItem: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        fontWeight: "normal",
        fontStyle: "italic",
    },
}));

const ObjectCard = ({ objectStructure }) => {
    const classes = useStyles();

    return (
        <Box className={classes.section}>
            <Typography variant="h4" className={classes.title}>
                {objectStructure.title}
            </Typography>
            <Typography variant="body1" className={classes.description}>
                {objectStructure.description}
            </Typography>
            <Typography variant="body1">{objectStructure.summary}</Typography>

            {objectStructure.json.map((json, index) => (
                <div style={{ marginTop: '32px' }}>
                    <Typography style={{ marginBottom: '4px' }} variant="h5" className={classes.description}>
                        {json.name}
                    </Typography>
                    <DisplayJSON json={json.structure} />
                </div>
            ))}

            {objectStructure.notes && (
                <div className={classes.notes}>
                    <Typography variant="body2">Notes:</Typography>
                    {objectStructure.notes.map((note, index) => (
                        <Typography
                            variant="body2"
                            key={index}
                            className={classes.noteItem}
                        >
                            {note}
                        </Typography>
                    ))}
                </div>
            )}
        </Box>
    );
};

export default ObjectCard;