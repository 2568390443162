import React from "react";

const DisplayJSON = ({ json }) => {
    const jsonString = JSON.stringify(json, null, 2);

    const styles = {
        container: {
            background: "#f5f5f5",
            padding: "16px",
            borderRadius: "4px",
            fontFamily: "monospace",
            fontSize: "14px",
            lineHeight: "1.5",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word"
        }
    };

    return (
        <div style={styles.container}>
            {jsonString}
        </div>
    );
};

export default DisplayJSON;