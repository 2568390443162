import CodeSnippet from "../elements/CodeSnippet"

const WidgetUsage = () => {
    return (
        <div>
            <h1>Widget Usage</h1>
            <p>We provide iframe style widgets that you can include in your application. </p>

            <CodeSnippet code={{ snippet: `<iframe src="http://localhost:3030/widget/item-detail" width={600} height={800} />` }} />

            <p>The iframe widget takes multiple attribtes that allow you to customize it</p>

            <h3>Item Detail Widget Example</h3>
            <iframe src="http://localhost:3030/widget/item-detail" width={600} height={800} />

        </div>
    )
}

export default WidgetUsage