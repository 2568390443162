const Authentication = () => {
    return (
        <div>
            <h1>Authentication</h1>
            <p>We use private API keys to authenticate requests. You will receive your unique API key when your sign up is approved. Your API key should be kept secret.</p>
            <p>To authenticate API calls with the API Key, set the “x-api-key” HTTP header value as your API key and the "x-client-id" HTTP header value has your Client ID.</p>
            <p>Once signed up, you'll receive an email confirmation with these details.</p>
            <p>You can also log into your account to view your keys</p>
        </div>
    )
}

export default Authentication