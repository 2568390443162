import SwaggerUI from "swagger-ui-react"
// import config from './swagger-config.yaml'

const Swagger = () => {
    const swaggerUrl = 'api.json'

    const requestInterceptor = (req) => {
        let path = req.url.split("http://localhost:3000/")[1] || req.url.split("https://docs.heali.com/")[1]

        req.url = 'https://healiapp.com/api/v2-b2b/' + (path || swaggerUrl); // replace with your server URL
        return req;
    };

    return <SwaggerUI url={'/api.json'} requestInterceptor={requestInterceptor} />
}

export default Swagger