const Overview = () => {
    return (
        <div>
            <h1>Overview</h1>
            <p>
                Welcome to Heali! Get familiar with our products and explore their features. Join top companies developers building nutrition and health-aware experiences with Heali’s technology and data.
            </p>
            <p>
                The Heali API gives developers access to up-to-date health and nutrition analysis on food formulas. Our REST API is simple to use: it has resource-oriented URLs and returns JSON, HTML and PDF responses. Please contact us to request access to the API!
            </p>
        </div>
    )
}

export default Overview