import { Button, Drawer, IconButton, List, ListItem, ListItemText, Switch, useMediaQuery, useTheme } from '@material-ui/core';
import menu from '../../data/menu.json';
import { useState } from 'react';
import useWindowWidth from '../../utility/useWindowWidth';
import { Menu as MenuIcon } from '@material-ui/icons';

const MobileMenu = ({ setActive, active, setDarkMode, darkMode, mobileMenuOpen, setMobileMenuOpen }) => {
    const windowWidth = useWindowWidth();

    const location = window.location.pathname;
    const theme = useTheme();
    const isMobile = windowWidth <= 700;

    const handleMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleMenuItemClick = (item) => {
        setActive(item.name);
        setMobileMenuOpen(false);
    };

    const handleDarkModeChange = (event) => {
        setDarkMode(event.target.checked);
        // Implement your dark mode logic here
    };

    return (
        <div

        >
            {isMobile ? (
                <>
                    <IconButton onClick={handleMenuToggle}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor="left" open={mobileMenuOpen} onClose={handleMenuToggle}>
                        <List>
                            {menu.menuItems.map((item, index) => (
                                <ListItem button key={index} onClick={() => handleMenuItemClick(item)}>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                </>
            ) : (
                <div style={{
                    position: 'fixed',
                    backgroundColor: '#ececec',
                    padding: '.25rem',
                    width: '210px',
                    height: 'calc(100vh - 80px)',
                    top: 70,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    {menu.menuItems.map((item, index) => (
                        <Button
                            key={index}
                            style={{ width: '200px', marginTop: '8px' }}
                            variant={active === item.name ? 'contained' : 'outlined'}
                            onClick={() => setActive(item.name)}
                        >
                            {item.name}
                        </Button>
                    ))}
                </div>
            )}

        </div>
    );
};

export default MobileMenu;
